import React, {Component, useState} from 'react';
import {Link, withRouter, useHistory} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label, DropdownMenu} from 'reactstrap';
import AppNavbar from './AppNavbar';

class RecoveryUser extends Component {

    emptyItem = {
        email: '',
        validationError: '',
        validationOk: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
    }


    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;

        if (item.email == '') {
            item.validationOk = null;
            item.validationErrorEmail = "Il campo Email non può essere vuoto";
        }  else {
            item.validationErrorEmail = null;
            await fetch('/B2BUserManager/users/pwdRecovery', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(item),
            });
            item.validationOk = "Email Inviata con successo";
        }

        this.setState({item});
    }


    render() {
        const {item} = this.state;
        const title = 'Recupero Password';
        return <div>
            <AppNavbar/>
            <Container>
                <br/>
                <div align="center">
                    <h4>{title}</h4>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    <div align="center">
                    <FormGroup>
                        <br/>
                        <h5>Inserire l'indirizzo email</h5>
                        <Input type="text" name="email" style={{width: "370px"}} id="email" value={item.email || ''}
                               onChange={this.handleChange} autoComplete="email"/>
                        <div>
                            {item.validationErrorEmail ? <label
                                style={{color: 'red', textAlign: "center"}}>{item.validationErrorEmail}</label> : null}
                        </div>
                        <div>
                            {item.validationOk ? <label
                                style={{color: 'green', textAlign: "center"}}>{item.validationOk}</label> : null}
                        </div>
                    </FormGroup>
                    </div>
                    <div align="center">
                    <FormGroup>
                        <br/>
                        <Button color="primary" type="submit">Invia</Button>{' '}
                        <Button color="secondary" tag={Link} to="/login">Chiudi</Button>
                    </FormGroup>
                    </div>
                </Form>
            </Container>
        </div>
    }
}

export default withRouter(RecoveryUser);