import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import AppNavbar from './AppNavbar';
import {Link, useHistory} from 'react-router-dom';
import ReactTimeout from 'react-timeout'


class UserList extends Component {

    constructor(props) {
        super(props);
        this.state = {users: []};
    }

    async GoToUserDetail(id){
        this.props.history.push('/users/' + id);
    }

    /*async Auth(){
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        if (!token || !userId) {
            const query = new URLSearchParams(this.props.location.search);
            const token = query.get('token');
            const userId = query.get('userId');
            console.log(token)//123
            console.log(userId)//123
            if(!token || !userId){
                this.props.history.push('/login');
            } else {
                const response = await this.CheckAuth({
                    token,
                    userId
                });

                if (!response.autorizzato) {
                    localStorage.removeItem('token', token);
                    localStorage.removeItem('userId', userId);
                    this.props.history.push('/login');
                } else {
                    localStorage.setItem('token', token);
                    localStorage.setItem('userId', userId);
                    localStorage.setItem('locId', response.locId);
                    this.props.history.push('/users');
                }
            }
        } else {

            const response = await this.CheckAuth({
                token,
                userId
            });

            if (!response.autorizzato) {
                localStorage.removeItem('token', token);
                localStorage.removeItem('userId', userId);
                this.props.history.push('/');
            }
        }
    }

    async CheckAuth(credential) {
        return fetch('/B2BUserManager/users/checkAuth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credential)
        })
            .then(data => data.json())
    }*/

    async logout(){
        const sessionToken = sessionStorage.getItem('token');
        await fetch('/B2BUserManager/users/logout', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({sessionToken}),
        })

        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('locId');
        sessionStorage.removeItem('accId');
        sessionStorage.removeItem('username');
        this.props.history.push('/login');
    }

    async getLocId(){
        return sessionStorage.getItem('locId');
    }

    /*componentDidMount() {
        this.Auth();
        const locId = localStorage.getItem('locId');
        if(locId) {
            fetch('/B2BUserManager/users?locId=' + locId)
                .then(response => response.json())
                .then(data => this.setState({users: data}));
        } else {
            this.props.history.push('/');
        }
    }*/

    componentDidMount() {
        //this.Auth();
        const locId = sessionStorage.getItem('locId');
        const userId = sessionStorage.getItem('userId');
        const token = sessionStorage.getItem('token');

        fetch('/B2BUserManager/users',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({locId,userId,token})
        })
            .then(response => response.json())
            .then(data => {
                const autorizzato = data.autorizzazione
                console.log(autorizzato);
                if (autorizzato === true) {
                    console.log("is Autorizzato");
                    this.setState({users: data.usersList});
                } else {
                    console.log("is not Autorizzato");
                    this.props.history.push('/login');
                }
            });
        /*} else {
            this.props.history.push('/');
        }*/
    }

    render() {
        const {users, isLoading} = this.state;

        if (isLoading) {
            return <p>Loading...</p>;
        }

        const userList = users.map(user => {
            let stato = "";
            let disableModifica=false;
            let descrButton= "Modifica";
            if(!user.modifica){
                disableModifica = true;
                descrButton = "Dettaglio";
            }
            if(user.sospeso)
                stato = "Sospeso";
            else if (user.bloccato)
                stato = "Bloccato";
            else
                stato = "Attivo";

            return <tr key={user.idUtente}>
                <td style={{whiteSpace: 'nowrap'}}>{user.username}</td>
                <td>{user.email}</td>
                <td>{user.nome}</td>
                <td>{user.cognome}</td>
                <td>{stato}</td>
                <td>
                    <ButtonGroup>
                        <Button size="sm" color="primary" tag={Link} to="#" onClick={() => this.GoToUserDetail(user.idUtente)}>{descrButton}</Button>
                    </ButtonGroup>
                </td>
            </tr>
        });

        return (
            <div>
                <AppNavbar/>
                <Container fluid >
                    <br/>
                    <div align="center" >
                    <h4>Utenti Censiti</h4>
                    </div>
                    <div align="right">
                        <Button color="black">{sessionStorage.getItem('username')}</Button>
                    </div>
                    <div align="right">
                        <Button color="black">{sessionStorage.getItem('locId')}</Button>
                    </div>
                    <div align="right">
                        <Button color="link" tag={Link} to="#" onClick={() => this.logout()}>Logout</Button>
                    </div>
                    <div>
                        <Button color="success" tag={Link} to="/users/new">Nuovo Utente</Button>
                    </div>
                    <Table className="mt-4">
                        <thead>
                        <tr>
                            <th width="20%">Username</th>
                            <th width="20%">Email</th>
                            <th width="20%">Nome</th>
                            <th width="20%">Cognome</th>
                            <th width="20%">Stato</th>
                            <th width="40%"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {userList}
                        </tbody>
                    </Table>

                </Container>
            </div>
        );
    }
}
export default UserList;