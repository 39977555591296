import './App.css';
import Login from './Login';
import Home from './Home';
import {HashRouter as Router, Route, Switch, useHistory} from 'react-router-dom';
import UserList from './UserList';
import UserEdit from './UserEdit';
import RecoveryUser from './RecoveryUser';
import { useIdleTimer } from 'react-idle-timer';
import global from './COSTANTI';

export default function App() {
    const history = useHistory();
    const token = sessionStorage.getItem('token');
        const onIdle = () => {
            fetch('/B2BUserManager/users/logout', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({token}),
            })

            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('locId');
            sessionStorage.removeItem('accId');
        }

        useIdleTimer({
            onIdle,
            timeout: 2000000, //10 minute idle timeout
        })

          return (
                <Router>
                  <Switch>
                      <Route path='/' exact={true} component={Home}/>
                      <Route path='/login' exact={true} component={Login}/>
                      <Route path='/users' exact={true} component={UserList}/>
                      <Route path='/users/checkAuth' exact={true} component={UserList}/>
                      <Route path='/users/:id' exact={true} component={UserEdit}/>
                      <Route path='/recovery' exact={true} component={RecoveryUser}/>
                  </Switch>
              </Router>
          )
}