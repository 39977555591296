import React, {Component, useState} from 'react';
import {useHistory} from "react-router-dom";
import AppNavbar from "./AppNavbar";

async function CheckAuth(credential) {
    return fetch('/B2BUserManager/users/checkAuth', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credential)
    })
        .then(data => data.json());
}


export default function Home() {
    const history = useHistory();
    const token = sessionStorage.getItem('token');
    const userId = sessionStorage.getItem('userId');

    if (!token || !userId) {
        // Redirect the user to the login page
        console.log("token:" + token);
        history.push('/login');
    } else {
        history.push('/users');
    }
}